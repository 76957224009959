import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Adoptons tous une « Green Routine » ✨`}</p>
    <p>{`Tout au long de notre quotidien, de nombreuses alternatives zéro déchet peuvent remplacer les produits que l’on utilise habituellement. `}</p>
    <p><a parentName="p" {...{
        "href": "outlook-data-detector://2"
      }}>{`Du 19 au 23 juillet`}</a>{`, @laconsignegreengo souhaite célébrer ces marques à travers une semaine de jeu concours sous le thème de la routine zéro déchet, la « Green Routine » !`}</p>
    <p>{`Tenez-vous prêt ! De nombreux lots sont à gagner 🎁`}</p>
    <p>{`Retrouvez lundi @commeavantbio, mardi @lespetitsbidons, mercredi @laconsignegreengo, jeudi @by_tilineatelierparis et vendredi @respectueuse.bio !  `}</p>
    <p>{`Rendez-vous sur `}<a parentName="p" {...{
        "href": "https://www.instagram.com/laconsignegreengo/"
      }}>{`notre page instagram`}</a>{` pour ne rien râter 🥳`}</p>
    <p>{`#`}{`greenroutine `}</p>
    <p>{`⬇️`}</p>
    <p>{`Mentions légales du concours La Consigne GreenGo - « Green Routine » :⁠`}</p>
    <p>{`ARTICLE 1 : Le jeu concours organisé par  La Consigne GreenGo intitulé « Green Routine » est ouvert aux personnes qui résident en France uniquement (hors corse et dom-tom). Le jeu concours débute le 19 juillet 2021 à 08h et se termine le 30 juillet 2021 à 19h. `}</p>
    <p>{`ARTICLE 2 : La participation à ce jeu est gratuite et sans obligation d'achat. `}</p>
    <p>{`ARTICLE 3 : Ce jeu-concours n’est ni organisé ni parrainé par Instagram.`}</p>
    <p>{`ARTICLE 4 : Le jeu prévoit 5 gagnants tirés au sort parmi toutes les personnes qui respectent les conditions de participation.`}</p>
    <p>{`ARTICLE 5 : Le gagnant sera informé par message privé au plus tard 7 jours après la fin du jeu concours. Son adresse postale lui sera demandée, pour lui envoyer le lot et ce conformément au Code Professionnel de la fédération des entreprises de Vente à Distance. `}</p>
    <p>{`ARTICLE 6 : La société La Consigne GreenGo ne pourra être tenue pour responsable si, pour des raisons indépendantes de sa volonté, l'opération, ses modalités et/ou les prix devaient être partiellement ou totalement reportés, modifiés ou annulés.`}</p>
    <p>{`ARTICLE 7 : Le simple fait de participer implique l'acceptation du règlement et des instructions. `}</p>
    <p>{`Ceci constitue le règlement complet du jeu « Green Routine » - SAS La Consigne GreenGo – 38 Rue Des Jeuneurs, 75002 Paris`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      